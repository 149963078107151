import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { InputTextComponent } from 'projects/content-service-cms/src/app/components/inputs/input-text/input-text.component';
import { CollectionScreenStore } from '../../collection-screen.state';
import { ICollection } from '../../../collection-overview/collection.interface';

@Component({
  selector: 'app-collection-info',
  standalone: true,
  imports: [InputTextComponent, CommonModule],
  templateUrl: './collection-info.component.html',
  styleUrl: './collection-info.component.scss',
})
export class CollectionInfoComponent {
  onFileSelected($event: Event) {
    const target = <HTMLInputElement>$event.target;
    const files = Array.from(target.files);
    if (files === undefined || files.length === 0) {
      return;
    }
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const fileContent = event.target?.result as string;
      try {
        const collection: ICollection = JSON.parse(fileContent);
        collection.settings &&
          this.createCollectionStore.setSettings(collection.settings);
        collection.avatars &&
          this.createCollectionStore.loadAvatars(collection.avatars);
        collection.scenes &&
          this.createCollectionStore.loadScenes(collection.scenes);
        collection.garments &&
          this.createCollectionStore.loadGarments(collection.garments);
        collection.outfits &&
          this.createCollectionStore.loadOutfits(collection.outfits);
      } catch (error) {
        console.error('failed to import settings', error);
      }
    };
    fileReader.readAsText(file);
  }
  constructor(public createCollectionStore: CollectionScreenStore) {}
}
