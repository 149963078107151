<div class="d-flex flex-row mb-3">
  <div class="d-flex flex-column thumbnailContainer">
    <img
      class="thumbnail"
      src="{{
        (this.createCollectionStore.infoImage$ | async) === ''
          ? '../../../assets/img/rr_overlay.png'
          : (this.createCollectionStore.infoImage$ | async)
      }}"
    />
  </div>
  <div class="d-flex flex-column ml-3 info-container">
    <app-input-text
      id="create-collection-name-txt"
      class="mb-5 inputField"
      placeholder="Name"
      [required]="true"
      [value]="this.createCollectionStore.infoName$ | async"
      (valueChange)="this.createCollectionStore.setInfoName($event.value)"
    ></app-input-text>

    <app-input-text
      id="create-collection-imageUrl-txt"
      placeholder="Image URL"
      [required]="true"
      [value]="this.createCollectionStore.infoImage$ | async"
      (valueChange)="this.createCollectionStore.setInfoImageUrl($event.value)"
    ></app-input-text>
    <input
      #fileinput
      id="fileinput"
      class="file-input"
      type="file"
      multiple="false"
      (change)="onFileSelected($event)"
    />
    <button class="btn btn-outline-primary import" (click)="fileinput.click()">
      Import from File
    </button>
  </div>
</div>
