/* eslint-disable max-lines-per-function */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AssetListComponent } from '../asset-list/asset-list.component';
import { OutfitListComponent } from '../outfit-list/outfit-list.component';
import {
  CollectionScreenStore,
  OutfitReference,
  OutfitReferenceJson,
  ScreenMode,
} from '../../collection-screen.state';
import {
  Subject,
  combineLatest,
  concatMap,
  firstValueFrom,
  lastValueFrom,
  take,
} from 'rxjs';

import {
  ICentralAssetPlatformService,
  ISimpleFileService,
} from '../../../data/api';
import {
  CollectionAvatarReference,
  ICollection,
} from '../../../collection-overview/collection.interface';
import { GarmentListComponent } from '../garment-list/garment-list.component';
import { CommonModule } from '@angular/common';
import { ICustomerService } from '../../../customer';
import { AlertService } from '../../../core/services/alert.service';
import { PreviewService } from '../../../collection-overview/services/collection-preview.service';
import { Router } from '@angular/router';
import { AvatarGroupListComponent } from '../avatar-group-list/avatar-group-list.component';
import { CollectionSettingsComponent } from '../collection-settings/collection-settings.component';

@Component({
  selector: 'app-collection-main',
  standalone: true,
  imports: [
    AssetListComponent,
    OutfitListComponent,
    GarmentListComponent,
    CommonModule,
    AvatarGroupListComponent,
    CollectionSettingsComponent,
  ],
  templateUrl: './collection-main.component.html',
  styleUrl: './collection-main.component.scss',
})
export class CollectionMainComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  //TODO: Just for testing, either remove or use service
  async downloadCollection($event: Event) {
    $event.preventDefault();
    const c = JSON.stringify(await this.mapCollection());
    const blob = new Blob([c], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  async previewCollection($event: Event) {
    $event.preventDefault();
    const collection = await this.mapCollection();
    const organizationId = (
      await firstValueFrom(this._customerService.currentCustomer$)
    ).id;
    const ref =
      'gid://pictofit/collection/' + collection.info.name.toLowerCase();
    this._previewService.show(organizationId, ref, {
      id: '',
      collection,
    });
  }

  async createCollection() {
    //first check if reference already exists
    const name = (await firstValueFrom(this.createCollectionStore.info$)).name;
    const ref =
      'gid://pictofit/collection/' + name.toLowerCase().replaceAll(' ', '_');
    const organizationId = (
      await firstValueFrom(this._customerService.currentCustomer$)
    ).id;
    if (this._screenMode === ScreenMode.NEW) {
      if (await this._cap.referenceExists(organizationId, ref)) {
        console.error('Reference already exists - ' + ref);
        this._alerrtService.showErrorAlert('Reference already exists - ' + ref);
        return;
      }
    }

    const c = await this.mapCollection();
    if (this._screenMode === ScreenMode.NEW) {
      this._cap.createCollection(organizationId, c).then((settingId) => {
        //try to create Ref
        this._cap
          .addExternalReferenceToSetting(settingId, ref)
          .then(() => {
            this._alerrtService.showSuccessAlert('Collection created - ' + ref);
            this.createCollectionStore.setMode(ScreenMode.EDIT);
            this.createCollectionStore.setId(settingId);
            this._router.navigate(['/collections', settingId]);
          })
          .catch((err) => {
            console.error('Collection could not be created - ' + err);
            this._alerrtService.showErrorAlert(
              'Collection could not be created - ' + err,
            );
          });
      });
    } else {
      const id = await firstValueFrom(this.createCollectionStore.id$);
      this._cap
        .updateCollection(id, c)
        .then(() => {
          this._alerrtService.showSuccessAlert('Collection updated - ' + ref);
        })
        .catch((err) => {
          console.error('Collection could not be updated - ' + err);
          this._alerrtService.showErrorAlert(
            'Collection could not be updated - ' + err,
          );
        });
    }
  }

  private _onDestroy$: Subject<void>;
  private _screenMode: ScreenMode;
  public _inputDataValid: boolean = false;
  constructor(
    public createCollectionStore: CollectionScreenStore,
    private _previewService: PreviewService,
    private _fs: ISimpleFileService,
    private _cap: ICentralAssetPlatformService,
    private _customerService: ICustomerService,
    private _alerrtService: AlertService,
    private _router: Router,
  ) {}
  ngOnInit(): void {
    this.createCollectionStore.screenMode$.subscribe((mode) => {
      this._screenMode = mode;
    });
    combineLatest(
      this.createCollectionStore.infoImage$,
      this.createCollectionStore.infoName$,
    ).subscribe((data) => {
      if (data[0] === '' || data[1] === '') {
        this._inputDataValid = false;
      } else {
        this._inputDataValid = true;
      }
    });
  }
  ngAfterViewInit(): void {
    this._onDestroy$ = new Subject<void>();
  }
  ngOnDestroy(): void {
    if (this._onDestroy$) {
      this._onDestroy$.next();
      this._onDestroy$.complete();
      this._onDestroy$.unsubscribe();
      this._onDestroy$ = undefined;
    }
  }

  public async mapCollection() {
    const result: ICollection = {
      info: {
        name: '',
        imageUrl: '',
      },
      avatars: [],
      scenes: [],
      garments: [],
      outfits: [],
    };

    this.createCollectionStore.info$.pipe(take(1)).subscribe((data) => {
      result.info = data;
    });
    this.createCollectionStore.settings$.pipe(take(1)).subscribe((data) => {
      if (data !== undefined) result.settings = data;
    });
    this.createCollectionStore.avatars$.pipe(take(1)).subscribe((data) => {
      data.forEach((entry) => {
        const acc: CollectionAvatarReference[] = [];
        entry.references.map((r) => {
          acc.push({
            name: r.name,
            reference: r.reference,
            organizationId: r.organizationId,
            belongsTo: r.belongsTo,
            variant: r.variant,

            id: r.id,
            ...(r.belongsTo
              ? {
                  additionalInformation: {
                    ...r.additionalInformation,
                    displayName: entry.displayName,
                  },
                }
              : {}),
          });
        });
        result.avatars.push(...acc);
      });
    });
    this.createCollectionStore.scenes$.pipe(take(1)).subscribe((data) => {
      result.scenes = data.map((entry) => ({
        name: entry.name,
        reference: entry.reference,
        organizationId: entry.organizationId,
        id: entry.id,
      }));
    });
    this.createCollectionStore.garments$.pipe(take(1)).subscribe((data) => {
      result.garments = data.map((entry) => ({
        name: entry.name,
        reference: entry.reference,
        organizationId: entry.organizationId,
        garmentLink: entry.garmentLink,
        price: entry.price,
        id: entry.id,
      }));
    });

    const outfitsPromise = this.createCollectionStore.outfits$.pipe(
      take(1),
      concatMap(async (outfits: OutfitReferenceJson[]) => {
        const resultOutfits: OutfitReference[] = [];
        for (const outfit of outfits) {
          const resultUrl = outfit.resultUrl;
          const outfitData = await lastValueFrom(
            this._fs.readJsonFile(resultUrl),
          );
          resultOutfits.push(outfitData);
        }
        return resultOutfits;
      }),
    );

    const resultOutfits = await lastValueFrom(outfitsPromise);

    return {
      ...result,
      outfits: resultOutfits,
    };
  }
}
