<rr-card id="collection-list">
  <div class="main-content">
    <div class="row">
      <div class="col-7 scroll" cdkScrollable>
        <div class="row">
          <app-collection-info class="info"> </app-collection-info>
        </div>
        <div class="row">
          <app-collection-main class="d-flex flex-column w-100">
          </app-collection-main>
        </div>
      </div>
      <div class="col-5 scroll" cdkScrollable>
        <app-reference-selection class="d-flex flex-column">
        </app-reference-selection>
      </div>
    </div>
  </div>
</rr-card>
