import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AlertService } from '../../../core/services/alert.service';
import { ClipboardModule } from 'ngx-clipboard';

@Component({
  selector: 'app-copy-to-clipboard',
  standalone: true,
  imports: [CommonModule, ClipboardModule],
  templateUrl: './copy-to-clipboard.component.html',
  styleUrl: './copy-to-clipboard.component.scss',
})
export class CopyToClipboardComponent {
  @Input() data: string;

  constructor(private _alertService: AlertService) {}

  public showInfo(success: boolean) {
    if (success) {
      this._alertService.showSuccessAlert(`Copied to clipboard!`);
    } else {
      this._alertService.showErrorAlert(`Failed to copy to clipboard!`);
    }
  }
}
